* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  height: 100vh; /* fallback */
  height: 100dvh; /* dynamic viewport height */
}

.wrapper {
  height: 100%;
}

.banner {
  height: 0;
}

.banner-image {
  position: relative;
  width: 100%;
  height: 90px;
}

.logo {
  position: absolute;
  width: 200px;
  left: 14px;
  top: -5px;
}

.content-holder {
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 75px;
}

.content {
  display: grid;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 70%;
  @media (min-height: 600px) {
    max-height: 500px;
  }
}

.title {
  width: 170px;
  color: #0199E3;
  text-align: center;
  font-family: "Fredoka-SemiBold";
  font-size: 20.136px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 20.136px */
  letter-spacing: -0.366px;
  text-transform: uppercase;
  margin: 0 auto;
  padding-bottom: 15%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  @media (max-height: 600px) {
    align-items: self-end;
  }
}

.time-image {
  width: 100%;
  width: 155px;
}

.video {
  width: auto;
  height: 100%;
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  padding-top: 60px;
}

.description {
  width: 201px;
  color: #000;
  text-align: center;
  font-family: "Roboto-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  letter-spacing: -0.291px;
  opacity: 0.4;
}

.download-button {
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #0199E3;

  border: none;

  color: #FFF;
  font-family: "Fredoka-SemiBold";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.footer-image {
  position: absolute;
  bottom: 0;
  height: 205px;
  bottom: 0;
}